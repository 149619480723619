import { IGroup } from '@guider-global/shared-types';
import { createContext, PropsWithChildren, useState } from 'react';

export type GroupValues =
  | (Partial<IGroup> & { skillSlugs: string[] })
  | undefined;

export interface GroupContextProps {
  groupValues: GroupValues;
  setGroupValues: (groupValues: GroupValues) => void;
  resetState: () => void;
}

export const GroupsContext = createContext<GroupContextProps>({
  groupValues: undefined,
  setGroupValues: () => {
    throw new Error('setDraftGroup is not implemented');
  },
  resetState: () => {
    throw new Error('resetState is not implemented');
  },
});

export function GroupsProvider({ children }: Readonly<PropsWithChildren>) {
  const [groupValues, setGroupValues] = useState<GroupValues>(undefined);

  const resetState = () => {
    setGroupValues(undefined);
  };

  const value = {
    groupValues,
    setGroupValues,
    resetState,
  };

  return (
    <GroupsContext.Provider value={value}>{children}</GroupsContext.Provider>
  );
}
